import React from 'react'
import Placeholder from '../components/placeholder'
import Helmet from 'react-helmet'
import ResponsabileReparto from '../components/responsabile-reparto'
import Breadcrumbs from '../components/breadcrumbs'
import SelectServizi from '../components/select-servizi'
import DoveSiamo from '../components/dove-siamo'
import Layout from "../components/layout"
import DocumentiUtili from '../components/documenti-utili'
import { graphql } from "gatsby"
import Team from '../components/team'

class Cavalli extends React.Component {

  render() { 
    const result = this.props.data.allContentfulSchedaServizio;
    const servizi = !!result?result.edges:[];
    const intro = this.props.data.contentfulContenutoGenerico;
    const responsabile =  this.props.data.responsabile;

    const breadcrumb = [{"title":"Reparti","link":"/reparti/"},{"title":"Clinica equina"}];

    return (
      <Layout  location={this.props.location}>
        <div className="container">
          <Helmet title="Clinica equina"/>
          <Breadcrumbs breadcrumb={breadcrumb}/>
          <div className="row">
            <div className="col-md-8">
              <section id="servizi-cani-gatti" className="section" aria-labelledby="region1">
                <h1 id="region1" className="section__title">Clinica equina</h1>
                {!!intro && 
                  <div dangerouslySetInnerHTML={{ __html: intro.abstract.childMarkdownRemark.html }} >
                  
                  </div>
                }
                
                <h2><strong>Servizi clinici</strong></h2>
        
                <div className="row flex-wrap">
                  {servizi.map((item,index) => {
                  
                      let dataserv = {
                        "title": item.node.titolo,
                        "link": "/cavalli/"+item.node.friendlyUrl+"/"
                        };
                      
                        return ( 
                          <div className="col-6" key={index}>
                            <Placeholder data={dataserv}/>
                          </div>
                        ) 
                      
                  })}
                </div>
              </section>
            
            </div>
            <div className="col-md-4">
              <SelectServizi/>
              {!!responsabile && 
                <ResponsabileReparto data={responsabile}/>
              }
               {intro.team && 
                <Team data={intro} elenco={false}/>
              }
              {!!intro.titoloSpallaDestra &&
                <div className="webcontent">
                  <h2 className="webcontent__title">{intro.titoloSpallaDestra}</h2>
                    <div className="webcontent__abstract breakword" dangerouslySetInnerHTML={{ __html: intro.testoSpallaDestra.childMarkdownRemark.html }} >
                    
                    </div>
                </div>
              }
             
              {!!intro.attachment &&
                <DocumentiUtili data={intro.attachment}/>
              }
              
              <DoveSiamo/>  
            </div>
          </div>
        </div>
      </Layout>
    )
  }

}

export default Cavalli;


export const query = graphql`
query CavalliQuery{
  allContentfulSchedaServizio(sort: {fields: [titolo]}, filter: {specie: {in: "Cavalli"}, node_locale: {eq: "it-IT"} }) {
    edges {
     node {
      id
      titolo
      friendlyUrl
      specie
    	}
    }
  }
  contentfulContenutoGenerico (contentful_id: {eq: "4C3K6C7gdyuOsg6u2Sqwuy"}) {
  	abstract {
      childMarkdownRemark {
        html
      }
    }
    contentful_id
    titoloSpallaDestra
    testoSpallaDestra {
      childMarkdownRemark {
        html
      }
    }
    team: staff {
      nome
      cognome
      friendlyUrl
    }
    attachment {
      title
      file {
        url
      }
    }
  }
  responsabile: contentfulResponsabileReparto(reparti: {in: "Clinica equina"}, node_locale: {eq: "it-IT"}) {
    nome
    cognome
    schedaStaff {
      friendlyUrl
    }
    foto {
      file {
        url
      }
    }
  }
  serviziJson {
    diagnostici{
      horizontal {
        title
        subtitle
        img
        link
      }
    }
  }
}
`